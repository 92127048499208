<template>
	<div class="loginPanel">
                <div class="messageText">Пароль был успешно изменен.</div>
                <ButtonRed class="bclose mt30" text='Авторизоваться' @clickButton="gotoAutorize"></ButtonRed>
        </div>
</template>

<script>

        import ButtonRed from "../../common/ButtonRed.vue";

        export default {
	        props : ["email"],
                components : {ButtonRed},
		data : function() {
			return {
			}
		},

                methods : {
                        gotoAutorize() {
                                window.history.pushState({}, null, "/");
                                this.$emit("gotoAutorize");
                        }
                }
	}
</script>
