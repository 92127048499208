<template>
        <div class="loginPanel">
                <div class="bigText">Адрес электронной почты был успешно подтвержден.</div>
                <ButtonRed class="bclose mt30" text='Авторизоваться' @clickButton="gotoAutorize"></ButtonRed>
        </div>
</template>

<script>

        import ButtonRed from "../../common/ButtonRed.vue";

        export default {
                props: ["email", "error", "back"],
                components: {ButtonRed},
                data: () => {
                        return {}
                },

                methods: {
                        gotoAutorize() {
                                window.history.pushState({}, null, "/");
                                this.$emit("gotoAutorize");
                        }
                }
        }
</script>
