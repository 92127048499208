cxxx<template>
        <div class="loginPanel">
                <div class="lpTitle">Забыли пароль?</div>
                <input type="text" placeholder="Электронная почта" class="email mt50" v-model="email" @keyup.enter="enter"/>
                <div class="desc mt15">Введите адрес электронной почты, который используете для входа, и мы отправим ссылку для сброса пароля.</div>
                <buttonRed class="binput mt50 bshort" text='Отправить' :disable="email==''" @clickButton="postPass"></buttonRed>
                <buttonNone text='Войти на сайт' class="bps mt20 bshort" @clickButton="gotoLogin"></buttonNone>
        </div>
</template>

<script>
import ButtonRed from "../../common/ButtonRed"
import ButtonNone from "../../common/ButtonNone"
import g from "../../globals";

export default {
        components: {ButtonRed, ButtonNone},

        data: function () {
                return {
                        email: "",
                }
        },

        methods: {
                enter() {
                        this.email = this.email.trim();
                        if(!this.email) return
                        this.postPass()
                },

                async postPass() {
                        try {
                                this.email = this.email.trim();
                                await g.postUrl("auth/postPassword", {email: this.email});
                                this.$emit("forgotPasswordOk", this.email);
                        } catch (e) {
                                this.$emit("error", e);
                        }
                },
                gotoLogin() {
                        this.$emit("login");
                }
        }
}
</script>
